import { getData, setData } from '@core-ui/reducers_handlers';
import { EmployeeSchema } from '@/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'settings.profile.data';

export const getProfile = getData(reducerName);
export const setProfile = setData<EmployeeSchema>(reducerName);

export const setProfileFormLoading = createAction(`${reducerName}.SET_PROFILE_FORM_LOADING`);

export const updateEmployee = createAction<EmployeeSchema>(`${reducerName}.UPDATE_EMPLOYEE`);
