import Paper from '@mui/material/Paper';
import NoDataContainer from '@/components/NoDataContainer';
import ProfileForm from '@/pages/settings/ProfileRoot/components/ProfileForm';
import selector from './selector';

const style = { margin: '24px 0', flex: 1 };

const ProfileRoot = () => {
  return (
    <Paper elevation={0} style={style}>
      <NoDataContainer selector={selector}>
        <ProfileForm />
      </NoDataContainer>
    </Paper>
  );
};

export default ProfileRoot;
