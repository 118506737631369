import { combineReducers } from 'redux';
import modals, { IModalsState } from '@/pages/settings/Modals/reducer';
import profile, { IProfileState } from './ProfileRoot/reducer';

export interface ISettingsState {
  profile: IProfileState;
  modals: IModalsState;
}

export default combineReducers({
  modals,
  profile,
});
