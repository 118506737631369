import State from '@/app/types/state';
import { createSelector } from 'reselect';

const getState = (state: State) => state.settings.profile;

export default createSelector([getState], (state) => ({
  loading: state.loading,
  loaded: state.loaded,
  hasData: state.hasData,
  error: state.error,
}));
