import reducer, { Data } from '@core-ui/reducers_handlers';
import { EmployeeSchema } from '@/generated';
import { Action, handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface IProfileState extends Data<EmployeeSchema> {
  formLoading: boolean;
}

const defaultState = Immutable<IProfileState>({
  loaded: false,
  hasData: false,
  loading: false,
  formLoading: false,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setProfileFormLoading.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('formLoading', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
