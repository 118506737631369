import { Nullable } from '@core-ui/types';
import { PUT } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { throwError } from '@/app/utils/app';
import { showNotification } from '@/components/Notification/actions';
import { EmployeeSchema } from '@/generated';
import { closeSettingsModal, setSettingsModalLoading } from '@/pages/settings/Modals/actions';
import { getProfile } from '@/pages/settings/Modals/ChangePasswordModal/selector';
import { IChangePassword } from '@/pages/settings/types';
import { Action } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* changePassword({ payload }: Action<IChangePassword>) {
  try {
    yield put(setSettingsModalLoading({ loading: true }));

    const profile: Nullable<EmployeeSchema> = yield select(getProfile);

    if (!profile?.email) {
      throwError('User profile error');
    }

    yield call(PUT, '/employee/change_password', {
      old_password: payload.old_password,
      new_password: payload.new_password,
      user_email: profile?.email,
    });

    yield all([
      put(closeSettingsModal()),
      put(
        showNotification({
          variant: 'success',
          title: '',
          messageId: 'notification.success.text.password_change',
        })
      ),
    ]);
  } catch (e) {
    yield all([call(responseError, e), put(setSettingsModalLoading({ loading: false }))]);
  }
}

export default [takeLatest(actions.changePassword, changePassword)];
