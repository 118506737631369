import State from '@/app/types/state';
import { createSelector } from 'reselect';

const getProfile = (state: State) => state.settings.profile.value!;
const getFormLoading = (state: State) => state.settings.profile.formLoading;

export default createSelector([getProfile, getFormLoading], (profile, formLoading) => ({
  profile,
  formLoading,
}));
