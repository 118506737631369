import { useAppSelector } from '@/hooks/useAppSelector';
import { ChangePasswordModalData } from '@/pages/settings/Modals/command';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { closeSettingsModal } from './actions';
import ChangePasswordModal from './ChangePasswordModal';
import selector from './selector';

const SettingsModals: FC = () => {
  const dispatch = useDispatch();
  const state = useAppSelector(selector);

  const onClose = () => {
    dispatch(closeSettingsModal());
  };

  return (
    <>
      <ChangePasswordModal opened={state.opened && state.data instanceof ChangePasswordModalData} onClose={onClose} />
    </>
  );
};

export default SettingsModals;
